<template>
    <div class="header">
        <slot></slot>
        <ul class="right-header" v-show="!headShowLi">
            <li @click="toPach('lcb')" :class="tagColor=='lcb'?'tagColor':''">里程碑</li>
            <li @click="toPach('qyjj')" :class="(tagColor=='qyjj' || tagColor=='tscd')?'tagColor':''">企业简介</li>
            <li @click="toPach('cpfa')" :class="tagColor=='cpfa'?'tagColor':''">产品方案</li>
            <li @click="toPach('jjfa')" :class="tagColor=='jjfa'?'tagColor':''">解决方案</li>
            <li @click="toPach('lxwm')" :class="tagColor=='lxwm'?'tagColor':''" style="margin-right:40px">联系我们</li>
            <slot name="3D"></slot>
            <slot name="light"></slot>
        </ul>
    </div>
</template>

<script>
// const debounce = function (func, wait) {
//     let timeout
//     return function () {
//         let context = this // 新增
//         // 保存参数
//         let args = arguments // 新增

//         clearTimeout(timeout)
//         timeout = setTimeout(function () {
//             func.apply(context, args) // 修改
//         }, wait);
//     }
// }
export default {
    name: 'pageHeader',
    data () {
        return {
            style: {
                backgroundColor: "",
            },
            color: false,
            tagColor: 'logo',
        };
    },
    props: {
        headLiShow: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        headShowLi () {
            return this.headLiShow
        }
    },
    methods: {
        toPach (tag) {
            this.$emit('changePath', tag)
        },
        changeColor (tag) {
            this.tagColor = tag
        }
    },
    mounted () {
        // this.changeColor = debounce(this.changeColor, 160)
    }
}
</script>

<style scoped lang="less">
.header {
    width: calc(100% - 160px);
    left: 0;
    padding: 0 80px;
    height: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 88;
    // transition: all linear 0.5s;
    background: #fff;
    // background-color: #fff;
}
.right-header {
    display: flex;
    color: #000000;
    li {
        margin-right: 30px;
        height: 24px;
        font-size: 15px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        line-height: 24px;
        cursor: pointer;
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        -o-transition: -o-transform 0.3s;
        transition: transform 0.3s;
        opacity: 0.6;
        &:hover {
            -webkit-transform: scale(1.15);
            -moz-transform: scale(1.15);
            -ms-transform: scale(1.15);
            -o-transform: scale(1.15);
            transform: scale(1.15);
            opacity: 1;
        }
        img {
            width: 24px;
            height: 24px;
        }
    }
}
.tagColor {
    // color: red !important;
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -o-transform: scale(1.15);
    transform: scale(1.15);
    opacity: 1 !important;
    position: relative;
    // &::before {
    //     content: '';
    //     position: absolute;
    //     // bottom: 0;
    //     left: 50%;
    //     transform: translateX(-15px);
    //     display: inline-block;
    //     width: 30px;
    //     height: 3px;
    //     bottom: 0px;
    //     background-color: #000000;
    // }
}
</style>
