<template>
    <div class="banner">
        <div class="banner-box">
            <ul class="left-banner">
                <li class="li-animation1">我们是您的大恒炫璟</li>
                <li class="li-animation2">引领新潮流</li>
                <li class="li-animation3">开创您全新的3D体验</li>
            </ul>
            <ul class="left-banner-animation">
                <li class="li-animation4">大恒炫璟</li>
                <li class="li-animation5">大恒炫璟</li>
                <li class="li-animation6">大恒炫璟</li>
            </ul>
        </div>

        <!-- <div class="right-banner">
            <img class="family-banner" src="../../assets/heying.jpg" alt="heying">
            <img src="../../assets/downRun.gif" class="banner-img" alt="">
        </div> -->
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'bannerCom',
}
</script>

<style scoped lang="less">
.left-banner,
.left-banner-animation {
    // margin-left: 120px;
    height: 243px;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    li {
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        font-size: 64px;
        position: absolute;
        top: 0;
        // bottom: 0;
        left: 0;
        width: 100%;
    }

    // li {
    //     display: none;
    //     font-family: PingFang-SC-Heavy, PingFang-SC;
    //     font-weight: 800;
    //     font-size: 54px;
    // }
}

// .animation-box {
//     position: relative;
//     flex: 1;
//     height: 243px;
//     li {
//         font-family: PingFang-SC-Heavy, PingFang-SC;
//         font-weight: 800;
//         font-size: 54px;
//         position: absolute;
//         // top: 50%;
//         bottom: 0;
//         left: 0;
//         // transform: translateY(-50%);
//     }
// }

.banner {
    color: #000000;
    padding: 0 120px;
    display: flex;
    align-items: center;
    // justify-content: space-around;
    justify-content: space-between;
    padding-top: 150px;
    padding-bottom: 220px;
    position: relative;
    .banner-box {
        position: relative;
        width: 100%;
    }
    .right-banner {
        position: relative;
        .family-banner {
            width: 650px;
            height: 470px;
            // margin-right: 110px;
        }
        .banner-img {
            position: absolute;
            bottom: -200px;
            // right: 110px;
            right: 0;
            width: 100px;
            height: 100px;
        }
    }
}
.li-animation1 {
    // display: none;
    animation: an1 1s ease-in-out 0.5s alternate-reverse;
    animation-fill-mode: forwards;
}
.li-animation2 {
    animation: an2 1s ease-in-out 0.5s alternate-reverse;
    animation-fill-mode: forwards;
}
.li-animation3 {
    animation: an3 1s ease-in-out 0.5s alternate-reverse;
    animation-fill-mode: forwards;
}
.li-animation4 {
    // display: none;
    animation: an4 1s ease-in-out alternate-reverse;
    animation-fill-mode: forwards;
}
.li-animation5 {
    animation: an5 1s ease-in-out alternate-reverse;
    animation-fill-mode: forwards;
}
.li-animation6 {
    animation: an6 1s ease-in-out alternate-reverse;
    animation-fill-mode: forwards;
}
.left-banner-animation {
    animation: ani-box1 1s ease-in-out alternate-reverse;
    animation-fill-mode: forwards;
}
.left-banner {
    opacity: 0;
    animation: ani-box2 0.2s ease-in-out 1s alternate-reverse;
    animation-fill-mode: forwards;
}

@keyframes ani-box1 {
    0% {
        // display: none;
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
@keyframes ani-box2 {
    0% {
        // display: none;
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes an1 {
    0% {
        transform: translateY(-80px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes an3 {
    0% {
        transform: translateY(80px);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes an4 {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-50px);
        color: #00a9e4;
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes an5 {
    0% {
        transform: translateY(0px);
        color: #000;
    }
    50% {
        transform: translateY(0px);
        color: #e94a38;
    }
    100% {
        transform: translateY(0px);
        color: #000;
    }
}
@keyframes an6 {
    0% {
        transform: translateY(0);
        display: none;
    }
    50% {
        transform: translateY(50px);
        color: #8bc333;
    }
    100% {
        transform: translateY(0);
    }
}
</style>
