<template>
    <div class="lxwm">
        <div>
            <div class="shadow"></div>
            <div class="lxwm-main">
                <div>
                    <h2>联系我们</h2>
                    <p>期待与您的合作，同时也感谢您对公司的发展提出您宝贵的意见</p>
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'lxwmCom',

}
</script>

<style scoped lang="less">
.lxwm {
    height: calc(100vh - 150px);
    width: 100%;
    position: relative;
    // > div {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    // }
    div.shadow {
        // content: '';
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 1;
        background-color: #fff;
        width: 100%;
        height: 100%;
        // border-radius: 20px;
    }
    .lxwm-main {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        // padding: calc(15vh + 100px) 198px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../../assets/lxwm.jpg') !important;
        z-index: 2;
        background-size: 100% 100%;
        > div {
            height: 400px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
    }
    h2 {
        font-size: 44px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        color: #ffffff;
        line-height: 62px;
    }
    p {
        font-size: 22px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        color: #ffffff;
        line-height: 54px;
        margin-bottom: 20px;
    }
    div.lxwm-btn {
        width: 390px;
        height: 64px;
        border-radius: 32px;
        border: 1px solid rgba(255, 255, 255, 0.9);

        font-size: 22px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        color: #ffffff;
        line-height: 64px;
        text-align: center;
        cursor: pointer;
    }
}
</style>
