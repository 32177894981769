<template>
    <div class="qyjj">
        <img src="../../assets/qyzp.jpg" alt="">
        <h4 class="h4-title-pc margin-title">大恒炫璟</h4>
        <div class="qyjj-box">
            <div class="qyjj-info">
                依托大恒集团在三维渲染引擎、人工智能内容生成技术、专业视音频处理能力等方面的技术积累，自主研发智能三维可交互场景编辑工具，实现跨平台多终端的AIGC三维动态场景展示与互动，为客户提供三维交互场景定制、开发和销售服务。
            </div>
            <slot></slot>
            <!-- <img src="" class="gif-loading" alt=""> -->
        </div>

    </div>
</template>

<script>
export default {
    name: 'qyjjCom',
}
</script>

<style scoped lang="less">
.qyjj {
    padding: 0 120px;
    padding-bottom: 100px;
    color: #000000;
    // height: 600px;
    > img {
        width: 100%;
        // height: 339px;
        border-radius: 20px;
        background-color: #f2f2f2;
    }
    .margin-title {
        margin-top: 80px;
        margin-bottom: 30px;
    }

    .qyjj-box {
        display: flex;
        width: 1400px;
        justify-content: space-between;
        align-items: flex-end;
        .qyjj-info {
            width: 780px;
            font-size: 22px;
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            line-height: 44px;
            letter-spacing: 1px;
        }
        .gif-loading {
            width: 112px;
            height: 130px;
        }
    }
}
</style>
