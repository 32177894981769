<template>
    <ul class="cpfa">
        <!-- <div class="shadow"></div> -->
        <li class="top-box cp-box">
            <!-- <img class="img-box-cp" src="../../assets/xnr.gif" alt=""> -->
            <div class="videoBox">
                <img class="img-box-cp" src="../../assets/xnr.gif" alt="" />
                <!-- <video class="img-box-cp" src="../../assets/xnr.mp4" ref="video" autoplay="true" loop="true" muted="true"></video> -->
            </div>
            <!-- <video class="img-box-cp" controls="controls">
                <source src="../../assets/2222.mp4" autoplay="true" type="video/mp4" />
            </video> -->
            <!-- <showView></showView> -->
            <div class="info-cpfa">
                <h4 class="h4-title">
                    超写实<br />
                    智能数字人
                </h4>
                <p>
                    超写实智能数字人，是由大恒炫璟自主研发，面向文旅、教育、政务、金融、公共交通、政务、电商直播、运营商、旅游等行业。通过语音识别、语音合成、自然语言理解、图像处理、口唇驱动及虚拟人合成等核心技术，旨在为特定行业客户提供互动交流、业务办理、问题咨询、服务导览，实现数字人与真人的“面对面”实时交互，解决用户实际业务问题的创新性、智能化产品解决方案，为客户创造实际应用价值。
                </p>
            </div>
        </li>
    </ul>
</template>

<script>
// import showView from './showView.vue'
export default {
    name: 'cpfaCom',
    components: {
        // showView
    },
}
</script>

<style scoped lang="less">
.cpfa {
    // box-shadow: ;
    // width: 1750px;
    // margin: 0 auto;
    // padding: 0 80px;
    width: 100vw;
    position: relative;
    overflow: hidden;
    // div.shadow {
    //     // content: '';
    //     position: absolute;
    //     display: block;
    //     top: 0px;
    //     z-index: 1;
    //     background-color: #fff;
    //     width: 100%;
    //     height: 60px;
    //     width: calc(100% - 240px);
    //     left: 120px;
    //     // border-radius: 20px;
    //     box-shadow: -3px -8px 30px 0px rgb(0 0 0 / 80%);
    // }
    .h4-title {
        margin-top: 4vh;
        padding-top: 30px;
        margin-bottom: 4vh;
        margin-left: 40px;
    }
    .top-box {
        padding-top: 180ox;
        position: relative;
        z-index: 2;
    }
    .center-box {
        position: relative;
    }
    .background-info {
        position: absolute;
        background-image: url(../../assets/diwen.png);
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 1;
        // height: 100%;
    }
    li.cp-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        // justify-content: space-between;
        // background-color: #fff;
        // padding: 200px 0px;
        position: relative;
        width: 100%;
        margin: 0 auto;
        height: 100vh;
        margin: auto 0;
        .videoBox {
            margin-top: 76px;
            height: 36vh;
            width: 100%;
            overflow: hidden;
            position: relative;
            .img-box-cp {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                // width: 760px;
                z-index: 1;
                object-fit: cover;
                // background-color: rgba(0, 0, 0, 0.3);
                // margin-right: 80px;
            }
        }

        p {
            font-size: 36px;
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            color: #858a8c;
            line-height: 64px;
            box-sizing: border-box;
            padding: 0 40px;
        }
        h4 {
            line-height: 54px;
            height: unset;
        }
        .info-cpfa {
            width: 100%;
            z-index: 1;
        }
        .flex-just {
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
        }
        .info-span {
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            color: #858a8c;
            margin-left: 40px;
            line-height: 32px;
            // margin-bottom: 35px;
            margin-top: 60px;
            li {
                font-size: 16px;
            }
        }
    }
}
</style>
