<template>
    <ul class="jjfa">
        <div class="center-box">
            <div class="background-info"></div>
            <div class="box">
                <div style="text-align: center;">
                    <h4 class="h4-title-pc"><span class="left"></span>解决方案<span class="right"></span></h4>
                </div>
                <ul>
                    <li @click="toPage(1)">
                        <div class="img1">
                            <div class="main-text">
                                <h5>
                                    ———沉浸式感受历史与科技之美
                                </h5>
                                <p>
                                    元宇宙让博物馆沉浸式互动体验呈现出新的状态。元宇宙所构建的沉浸式交互场景体验，通过全景视频、图像和全程直播等动态方式，一方面使数字技术和文物的互动，另一方面对文物更深更多维的研究之后搭建的全面数字化场景，以及让博物馆观众主动参与创造的数字文博创意空间，让真实世界的每一个人都能“进入”文物“活”起来的无限活力的元宇宙世界。
                                </p>
                            </div>
                        </div>
                        <p class="title-demo">
                            博物馆元宇宙
                        </p>
                    </li>
                    <li @click="toPage(2)">
                        <div class="img2">
                            <div class="main-text">
                                <h5>
                                    ———智能制造的未来
                                </h5>
                                <p>
                                    通过打造与实体工业经济映射和交互的虚拟世界，构建全息智能制造和智能经济体系，管理工业产品全生命周期，让企业和消费者参与其中。在工业领域有大量相关的工业软件、系统、互联网平台，进入元宇宙时代，这些软件、系统、平台将踏上一个新台阶。
                                </p>
                            </div>
                        </div>
                        <p class="title-demo">
                            工业元宇宙
                        </p>
                    </li>
                    <li @click="toPage(3)">
                        <div class="img3">
                            <div class="main-text">
                                <h5>
                                    ———科技赋予展厅灵魂
                                </h5>
                                <p>
                                    在元宇宙沉浸式展厅中，空间是极限扩展的，无论展厅中新增了多少个场景功能区，空间都能无限扩容。还能1:1复刻来自现实世界的超逼真环境。大到整个展馆，小到内部结构都能搬运到元宇宙沉浸式展厅。而元宇宙沉浸式展厅具有快速更换展览内容的高功能，上传布展所需的音乐、视频、图片、文字、动画等各类型素材，一键拖拉替换旧素材，即可全馆内容换新。
                                </p>
                            </div>
                        </div>
                        <p class="title-demo">
                            会展元宇宙
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </ul>
</template>

<script>
// import showView from './showView.vue'
export default {
    name: 'cpfaCom',
    components: {
        // showView
    },
    methods: {
        toPage (val) {
            switch (val) {
                // case 1:
                //     window.open("http://39.97.43.162:6680/#/", "_blank");
                //     break;
                // case 2:
                //     window.open("http://39.97.43.162:6681/#/", "_blank");
                //     break;
                // case 3:
                //     window.open("http://39.97.43.162:6682/#/", "_blank");
                //     break;

                case 1:
                    window.open("https://www.magicalscene.com/demos/demo1/", "_blank");
                    break;
                case 2:
                    window.open("https://www.magicalscene.com/demos/demo2", "_blank");
                    break;
                case 3:
                    window.open("https://www.magicalscene.com/demos/demo3/", "_blank");
                    break;
            }
        }
    }
}
</script>

<style scoped lang="less">
.jjfa {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;

    .center-box {
        position: relative;
        height: 100vh;
        padding: 0px 150px;
        margin: auto;
        box-sizing: border-box;
        background-color: #fff;
        .h4-title-pc {
            display: inline-block;
            margin-bottom: 70px;
            position: relative;
            span.left {
                display: inline-block;
                width: 200px;
                height: 2px;
                position: absolute;
                top: 50%;
                left: -280px;
                background-color: #000000;
            }
            span.right {
                display: inline-block;
                width: 200px;
                height: 2px;
                position: absolute;
                top: 50%;
                left: 260px;
                background-color: #000000;
            }
        }
        .box {
            z-index: 3;
            width: calc(100% - 300px);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            ul {
                display: flex;
                li {
                    flex: 1;
                    padding: 205px;
                    margin: 0 15px;
                    position: relative;
                    cursor: pointer;
                    > div {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: calc(100% - 60px);
                        border-radius: 22px;
                        overflow: hidden;
                    }
                    .title-demo {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        text-align: center;
                        font-size: 27px;
                        font-family: PingFang-SC-Heavy, PingFang-SC;
                        font-weight: 800;
                        color: #000000;
                    }
                    .main-text {
                        transform: translateY(-100%);
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        transition: all 0.5s;
                        background-image: linear-gradient(
                            rgba(0, 0, 0, 0.5),
                            rgba(0, 0, 0, 0.05)
                        );
                        h5 {
                            color: #fff;
                            font-size: 17px;
                            font-family: PingFang-SC-Heavy, PingFang-SC;
                            font-weight: 800;
                            margin: 30px 37px;
                            margin-top: 0;
                            padding-top: 30px;
                        }
                        p {
                            color: #fff;
                            width: 100%;
                            padding: 0 37px;
                            box-sizing: border-box;
                            font-size: 17px;
                            font-family: PingFang-SC-Heavy, PingFang-SC;
                            font-weight: 800;
                            line-height: 35px;
                            text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.5);
                        }
                    }
                    &:hover {
                        .main-text {
                            opacity: 1;
                            transform: translateY(0px);
                        }
                    }
                }
            }
            .img1 {
                background: url('../../assets/demo1.png') no-repeat 50% 50% /
                    100% 100%;
            }
            .img2 {
                background: url('../../assets/demo2.png') no-repeat 50% 50% /
                    100% 100%;
            }
            .img3 {
                background: url('../../assets/demo3.png') no-repeat 50% 50% /
                    100% 100%;
            }
        }
    }
    .background-info {
        position: absolute;
        background-image: url(../../assets/diwen.png);
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 1;
    }
}
</style>
