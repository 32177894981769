<template>
    <ul class="cpfa">
        <!-- <div class="shadow"></div> -->
        <li class="top-box cp-box">
            <!-- <img class="img-box-cp" src="../../assets/xnr.gif" alt=""> -->
            <video class="img-box-cp" src="../../assets/xnr.mp4" ref="video" autoplay="true" loop="true" muted="true"></video>
            <!-- <video class="img-box-cp" controls="controls">
                <source src="../../assets/2222.mp4" autoplay="true" type="video/mp4" />
            </video> -->
            <!-- <showView></showView> -->
            <div class="info-cpfa">
                <h4 class="h4-title-pc">
                    超写实<br />
                    智能数字人
                </h4>
                <p>
                    超写实智能数字人，是由大恒炫璟自主研发，面向文旅、教育、政务、金融、公共交通、政务、电商直播、运营商、旅游等行业。通过语音识别、语音合成、自然语言理解、图像处理、口唇驱动及虚拟人合成等核心技术，旨在为特定行业客户提供互动交流、业务办理、问题咨询、服务导览，实现数字人与真人的“面对面”实时交互，解决用户实际业务问题的创新性、智能化产品解决方案，为客户创造实际应用价值。
                </p>
            </div>
        </li>
        <div class="center-box">
            <div class="background-info"></div>
            <li class="cp-box ">
                <div class="info-cpfa">
                    <h4 class="h4-title-pc">
                        增值3D++<br />
                        资产管理平台
                    </h4>
                    <p>
                        3D++资产管理平台台有效解决了传统三维信息割裂、无法统一管理等痛点，同时轻量化打通了三维信息交互多项壁垒，让用户实现一站式可视化互动管控，处理过程全自动化，颠覆了传统三维人工处理方式，为可视化生态提供了全新的可能。
                    </p>
                </div>
                <img class="img-box-cp" src="../../assets/cp1.gif" alt="">
            </li>
        </div>

        <li class="cp-box">
            <!-- <img class="img-box-cp" src="../../assets/cp3.png" alt=""> -->
            <video class="img-box-cp" src="../../assets/cj.mp4" ref="video" autoplay="true" loop="true" muted="true"></video>
            <div class="info-cpfa flex-just">
                <div>
                    <h4 class="h4-title-pc">
                        互动场景构建<br />
                        管理平台
                    </h4>
                    <p>
                        场景搭建管理平台不仅提供了一个简单易用、功能强大的web 3D 搭建工具，同时基于平台丰富的 3D 模型、材质、环境光资源库，供用户快速搭建 3D 场景，从而大大提升了场景搭建效率。并通过互动使得您搭建的场景脱颖而出。
                    </p>
                </div>
                <ul class="info-span">
                    <li>1、为模型添加描述和标注</li>
                    <li>2、在材质之间进行切换</li>
                    <li>3、更改三维模型的颜色或部分</li>
                </ul>
            </div>
        </li>
    </ul>
</template>

<script>
// import showView from './showView.vue'
export default {
    name: 'cpfaCom',
    components: {
        // showView
    },
}
</script>

<style scoped lang="less">
.cpfa {
    // box-shadow: ;
    // width: 1750px;
    // margin: 0 auto;
    // padding: 0 80px;
    position: relative;
    box-shadow: -3px -8px 30px 0px rgb(0 0 0 / 40%);
    border-radius: 30px 30px 0 0;
    overflow: hidden;
    .marginR80 {
        margin-right: 80px;
    }
    div.shadow {
        // content: '';
        position: absolute;
        display: block;
        top: 0px;
        z-index: 1;
        background-color: #fff;
        width: 100%;
        height: 60px;
        width: calc(100% - 240px);
        left: 120px;
        // border-radius: 20px;
        box-shadow: -3px -8px 30px 0px rgb(0 0 0 / 80%);
    }
    .top-box {
        padding-top: 180ox;
        position: relative;
        z-index: 2;
    }
    .center-box {
        position: relative;
    }
    .background-info {
        position: absolute;
        background-image: url(../../assets/diwen.png);
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 1;
        // height: 100%;
    }
    li.cp-box {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        padding: 200px 0px;
        position: relative;
        width: 1510px;
        margin: 0 auto;
        .img-box-cp {
            width: 760px;
            height: 570px;
            border-radius: 30px;
            z-index: 1;
            object-fit: cover;
            background-color: rgba(0, 0, 0, 0.3);
            // margin-right: 80px;
        }
        p {
            font-size: 20px;
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            color: #858a8c;
            line-height: 44px;
        }
        h4 {
            line-height: 54px;
            height: unset;
        }
        .info-cpfa {
            width: 600px;
            z-index: 1;
        }
        .flex-just {
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
        }
        .info-span {
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            color: #858a8c;
            line-height: 32px;
            // margin-bottom: 35px;
            margin-top: 60px;
            li {
                font-size: 16px;
            }
        }
    }
}
</style>
