<template>
    <div class="jjfa">
        <h4 class="h4-title margin-title">解决方案</h4>
        <div class="swiper-container" id="swiperIndex2" ref="swiperDom">
            <div class="swiper-wrapper">
                <div class="swiper-slide" :key="1">
                    <div class="img-box">
                        <div class="img img1"></div>
                    </div>
                    <div class="mian-box">
                        <h5>
                            博物馆元宇宙
                        </h5>
                        <h6>——沉浸式感受历史与科技之美</h6>
                        <p>
                            元宇宙让博物馆沉浸式互动体验呈现出新的状态。元宇宙所构建的沉浸式交互场景体验，通过全景视频、图像和全程直播等动态方式，一方面使数字技术和文物的互动，另一方面对文物更深更多维的研究之后搭建的全面数字化场景，以及让博物馆观众主动参与创造的数字文博创意空间，让真实世界的每一个人都能“进入”文物“活”起来的无限活力的元宇宙世界。
                        </p>
                    </div>
                    <div class="btn" @click="toPage(1)">
                        探索
                    </div>
                </div>
                <div class="swiper-slide" :key="2">
                    <div class="img-box">
                        <div class="img img2"></div>
                    </div>
                    <div class="mian-box">
                        <h5>
                            工业元宇宙
                        </h5>
                        <h6>———智能制造的未来</h6>
                        <p>
                            通过打造与实体工业经济映射和交互的虚拟世界，构建全息智能制造和智能经济体系，管理工业产品全生命周期，让企业和消费者参与其中。在工业领域有大量相关的工业软件、系统、互联网平台，进入元宇宙时代，这些软件、系统、平台将踏上一个新台阶。
                        </p>
                    </div>
                    <div class="btn" @click="toPage(2)">
                        探索
                    </div>
                </div>
                <div class="swiper-slide" :key="3">
                    <div class="img-box">
                        <div class="img img3"></div>
                    </div>
                    <div class="mian-box">
                        <h5>
                            会展元宇宙
                        </h5>
                        <h6>———科技赋予展厅灵魂</h6>
                        <p>
                            在元宇宙沉浸式展厅中，空间是极限扩展的，无论展厅中新增了多少个场景功能区，空间都能无限扩容。还能1:1复刻来自现实世界的超逼真环境。大到整个展馆，小到内部结构都能搬运到元宇宙沉浸式展厅。而元宇宙沉浸式展厅具有快速更换展览内容的高功能，上传布展所需的音乐、视频、图片、文字、动画等各类型素材，一键拖拉替换旧素材，即可全馆内容换新。
                        </p>
                    </div>
                    <div class="btn" @click="toPage(3)">
                        探索
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-pagination"></div>

    </div>
</template>

<script>
import "swiper/css/swiper.css"; //引入swiper样式
import Swiper from "swiper"; //引入swiper
export default {
    name: 'jjfaCom',
    mounted () {
        this.mySwiper = new Swiper("#swiperIndex2", {
            pagination: {
                el: '.swiper-pagination',
            },
            direction: 'horizontal',
            loop: true,
            autoplay: 5000,
            slidesPerView: "auto",
            centeredSlides: true,
            spaceBetween: 11,
        });
    },
    methods: {
        toPage (val) {
            switch (val) {
                // case 1:
                //     window.open("http://39.97.43.162:6680/#/", "_blank");
                //     break;
                // case 2:
                //     window.open("http://39.97.43.162:6681/#/", "_blank");
                //     break;
                // case 3:
                //     window.open("http://39.97.43.162:6682/#/", "_blank");
                //     break;
                case 1:
                    window.open("https://www.magicalscene.com/demos/demo1/", "_blank");
                    break;
                case 2:
                    window.open("https://www.magicalscene.com/demos/demo2", "_blank");
                    break;
                case 3:
                    window.open("https://www.magicalscene.com/demos/demo3/", "_blank");
                    break;
            }
        }
    }
}

</script>

<style scoped lang="less">
.jjfa {
    color: #000000;
    height: 100vh;
    width: 100vw;
    position: relative;
    // padding: 40px;
    box-sizing: border-box;
    background-color: rgba(245, 246, 249, 1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    #swiperIndex2 {
        // height: 100vh;
        // overflow-y: auto;
        width: 100%;
        flex: 1;
        margin-bottom: 120px;
        --swiper-theme-color: #ff6600;
        --swiper-pagination-color: #00ff33; /* 两种都可以 */
    }
    .swiper-pagination {
        bottom: 50px;
        width: 100%;
    }
    :deep(.swiper-pagination-bullet) {
        width: 40px;
        height: 8px;
        margin: 0 10px;
        border-radius: unset !important;
        background-color: rgba(0, 0, 0, 0.1);
        opacity: 1 !important;
        &.swiper-pagination-bullet-active {
            background-color: rgba(0, 0, 0, 0.4);
        }
    }

    .swiper-slide {
        width: calc(100vw - 90px);
        height: 100%;
        position: relative;
        background-color: #fff;
        .btn {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 300px;
            height: 110px;
            background-color: #000;
            border-radius: 3px;
            color: #fff;
            font-size: 36px;
            font-weight: 500;
            bottom: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .mian-box {
        padding: 40px;
        h5 {
            font-size: 56px;
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            color: #000000;
            margin-top: 40px;
        }
        h6 {
            font-size: 46px;
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            color: #000000;
            margin-top: 20px;
        }
        p {
            margin-top: 40px;
            font-size: 32px;
            font-weight: 600;
            color: #858a8c;
            line-height: 60px;
        }
    }
    .img-box {
        position: relative;
        // width: 100%;
        padding: 290px;
        .img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .img1 {
            background: url('../../assets/demo1.png') no-repeat 50% 50% / 100%
                100%;
        }
        .img2 {
            background: url('../../assets/demo2.png') no-repeat 50% 50% / 100%
                100%;
        }
        .img3 {
            background: url('../../assets/demo3.png') no-repeat 50% 50% / 100%
                100%;
        }
    }

    .margin-title {
        margin-top: 60px;
        padding-top: 80px;
        margin-bottom: 60px;
        margin-left: 45px;
    }
}
</style>
