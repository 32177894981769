<template>
    <div id="Map" style="width: 100%;height: 100%;">
    </div>
</template>
<script>
//引入高德地图
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
    name: 'IndexMap',
    data () {
        return {
            map: null, //地图实例
        }
    },
    mounted () {
        this.initMap()
    },
    methods: {
        initMap () {
            AMapLoader.load({
                key: "523d62a249b4abca00c3a0514674bee6", // 申请好的Web端开发者Key，首次调用 load 时必填
                //2.0版本太卡了 ，所以使用的1.4.0版本  其插件也有不同  如：ToolBar
                version: "1.4.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                resizeEnable: true,
                plugins: [
                    "AMap.ToolBar", //工具条
                    "AMap.Scale", // 比例尺
                    "AMap.Geolocation", //定位
                ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap) => {
                this.map = new AMap.Map("Map", { //设置地图容器id
                    // 这里的参数有许多可根据需要添加  点击下面《map地图参数》跳转
                    viewMode: "3D", //是否为3D地图模式
                    zoom: 14, //初始化地图级别
                    zooms: [8, 16],
                    center: [116.305225, 39.982383], //初始化地图中心点位置
                });
                this.map.on("complete", () => { });
                var marker = new AMap.Marker({
                    map: this.map,
                    position: [116.305225, 39.982383],
                    icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png",
                });
                marker.on("click", () => {
                    this.map.setZoomAndCenter(16, [116.305225, 39.982383]);
                    this.addInfoWindow(AMap);
                });
                // this.addInfoWindow(AMap);
            }).catch(e => {
                console.log(e);
            })
        },
        //添加信息窗体
        addInfoWindow (AMap) {
            var infoWindow = new AMap.InfoWindow({
                anchor: "bottom-left",
                offset: new AMap.Pixel(2, -20),
                content:
                    '<div style="font-size:14px;margin:10px 0 5px 0;color:#000 !important;">' +
                    '大恒炫璟' +
                    '</div><div style="font-size:14px;padding-bottom:10px;color:#000 !important;">地址：' +
                    '大恒科技大厦北座15层' +
                    "</div>",
                showShadow: true,
            });

            infoWindow.close();
            infoWindow.open(this.map, [116.305225, 39.982383]);
        },
        destroyMap () {
            this.map && this.map.destroy();
        }
    },
    beforeUnmount () {
        this.destroyMap()
    },
}
</script>