<template>
    <div class="tscd">
        <div>
            <div class="tscd-main">
                <p>我们对创建生动有趣的元宇宙世界</p>
                <p>有强烈的好奇心和足够的能力</p>
                <img class="fc-tscd" src="../../assets/fc2.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tscdCom',
}
</script>

<style scoped lang="less">
.tscd {
    height: 1600px;
    z-index: 11;
    margin-top: 200px;
    position: relative;
    > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color: #ffffff;
    }
    .tscd-main {
        position: relative;
        width: 100%;
        background-image: url('../../assets/fc1.png');
        // box-shadow: 14px 19px 10px 5px rgb(0 0 0 / 50%);
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: -3px -8px 30px 0px rgb(0 0 0 / 70%);
        .fc-tscd {
            position: absolute;
            width: 287px;
            height: 440px;
            z-index: 50;
            right: 32px;
            top: 94px;
        }
        p {
            font-size: 44px;
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            color: #ffffff;
            line-height: 62px;
        }
    }
}
</style>
