<template>
    <div class="main-box">
        <div class="bottom-box">
            <IndexMap></IndexMap>
        </div>
        <h4 class="h4-title">{{title}}</h4>
        <ul>
            <li>电话：13261232580</li>
            <li>邮箱：xuanjing@magicalscene.com</li>
            <li>地址：北京市海淀区苏州街3号大恒科技大厦北座15层</li>
        </ul>
    </div>
</template>

<script>
import IndexMap from '../map/index.vue'
export default {
    name: 'ClickPopCom',
    components: {
        IndexMap
    },
    data () {
        return {
            title: '联系我们'
        }
    },
}
</script>

<style scoped lang="less">
.main-box {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    // padding: 0 40px;
    display: flex;
    margin-top: 76px;
    flex-direction: column;
    // justify-content: center;
    ul {
        // margin-bottom: 24px;
        margin-left: 40px;
        li {
            font-size: 36px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #858a8c;
            margin: 70px 0;
        }
    }
    .cls-btn {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 21px;
        height: 21px;
        cursor: pointer;
    }
    .bottom-box {
        width: 100%;
        height: 35vh;
    }
    .h4-title {
        margin-top: 4vh;
        padding-top: 30px;
        margin-bottom: 4vh;
        margin-left: 40px;
    }
}
</style>
