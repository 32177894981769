<template>
    <div class="page" :class="{unlight:light}">
        <pageHeader @changePath="toPath" :style="style" class="headerColor" :headLiShow="headLiShow" ref="headTop" :class="{color:color}">
            <div v-if="!light">
                <img src="../assets/LOGO.png" class="logoImg" alt="LOGO" @click="toPath('logo')" />
                <!-- <img v-if="!color" src="../assets/LOGO.png" class="logoImg" alt="LOGO" />
                <img v-else class="logoImg" src="../assets/blockLOGO.png" alt="LOGO"> -->
            </div>
            <div v-else>
                <img class="logoImg" src="../assets/anLOGO.png" @click="toPath('logo')" alt="LOGO">
            </div>

            <template v-slot:3D>
                <li class="img-li-head marginR20" v-if="!light"><img class="btn-img-head" src="../assets/3D.png" alt="" /></li>
                <li class="img-li-head marginR20" v-else><img class="btn-img-head" src="../assets/an3D.png" alt="" /></li>
            </template>
            <template v-slot:light>
                <li v-if="!light" style="margin:0"><img class="btn-img-head" src="../assets/unlight.png" @click="changeLight" alt="" /></li>
                <li v-else style="margin:0"><img class="btn-img-head" src="../assets/light.png" @click="changeLight" alt="" /></li>
            </template>
        </pageHeader>
        <div class="top-box" ref="topBox">
            <div :class="{tsClass:tsClass}" class="page-top" :style="tsStyle" ref="pageTop">
                <banner ref="logo">
                    <div class="right-banner">
                        <img class="family-banner" src="../assets/hy.png" alt="heying">
                        <!-- <img v-else class="family-banner" src="../assets/anheying.png" alt="heying"> -->
                        <img src="../assets/downRun.gif" @click="toPath('lcb')" v-if="!light" class="banner-img" alt="">
                        <img src="../assets/andownRun.gif" @click="toPath('lcb')" v-else class="banner-img" alt="">
                    </div>
                </banner>
                <lcb ref="lcb">
                    <ul :class="{lcbBox:lcbBox}" class="lcb-ul">
                        <li class="text text1">2022年2月， 北京大恒炫璟科技有限责任公司正式成立，是大恒科技（600288）面向元宇宙业务投资成立的控股子公司</li>
                        <li class="text text2">2022年3月，大恒炫璟全行业数字解决方案上线</li>
                        <li class="text text3">2022年5月，3D++资产管理平台V1.0正式上线</li>
                        <li class="text text4">2022年7月，3D++资产管理平台V2.0上线</li>
                        <li class="text text5">2022年9月，3D++资产管理平台V3.0上线</li>
                        <li class="text text6">2022年10月，大恒炫璟面向教育行业元宇宙实训室解决方案上线</li>
                        <li class="text text7">2022年12月，场景管理平台V1.0上线</li>
                    </ul>
                    <div class="btn-lcb" @click="showPop('加入我们')">Join us</div>
                </lcb>
                <qyjj ref="qyjj" style="position:relative">
                    <img src="../assets/up.gif" class="gif-loading" v-if="!light" alt="" />
                    <img src="../assets/unup.gif" class="gif-loading" v-else alt="" />
                </qyjj>
            </div>
        </div>
        <div class="page-bottom">
            <p :style="serarea" style="background:transparent !important;"></p>
            <div class="box-bagc">
                <tscd :class="{showTscd:showTscd}" ref="tscd"></tscd>
                <cpfa ref="cpfa"></cpfa>
                <jjfa ref="jjfa"></jjfa>
                <lxwm ref="lxwm">
                    <div class="lxwm-btn" @click="showPop('联系我们')">CONTACT US</div>
                </lxwm>
            </div>
            <pageFooter></pageFooter>
        </div>
        <ClickPop ref="ClickPop"></ClickPop>
    </div>
</template>

<script>
import banner from './badyCom/banner.vue'
import lcb from './badyCom/lcb.vue'
import qyjj from './badyCom/qyjj.vue'
import cpfa from './badyCom/cpfa.vue'
import lxwm from './badyCom/lxwm.vue'
import tscd from './badyCom/tscd.vue'
import jjfa from './map/jjfa.vue'

import ClickPop from './badyCom/ClickPop.vue'

import pageHeader from '../components/pageHeader.vue'
import pageFooter from '../components/pageFooter.vue'
const debounce = function (func, wait) {
    let timeout
    return function () {
        let context = this // 新增
        // 保存参数
        let args = arguments // 新增

        clearTimeout(timeout)
        timeout = setTimeout(function () {
            func.apply(context, args) // 修改
        }, wait);
    }
}
const arrList = ['logo', 'lcb', 'qyjj', 'tscd', 'cpfa', 'jjfa', 'lxwm']  // 依次为滚动条滚动的顺序方便header头切换样式

export default {
    name: 'indexPage',
    components: {
        banner,
        lcb,
        qyjj,
        pageHeader,
        cpfa,
        lxwm,
        tscd,
        pageFooter,
        ClickPop,
        jjfa
    },
    data () {
        return {
            color: false,
            tsStyle: {
                top: 0,
                opacity: 1
            },
            serarea: {
                height: 0,
                width: '1px'
            },
            tsClass: false,
            pageHeight: {
                height: 'auto'
            },
            topHeight: 0,    // 折页处理需要用到
            light: false,   // 换肤用常规css处理的
            lcbBox: false,   // 里程碑动画展示触发条件
            isClick: false,     // 判断数据是否加载好在跳转滚轮
            showTscd: false,  // 隐藏tscd页
            selfTopArr: [],
            selfHeightArr: [],
            createShow: true,
            headLiShow: true
        };
    },
    mounted () {
        this.tsClass = false;
        // 计算高度方便后期运算
        // setTimeout(() => {
        //     this.tsClass = false;
        //     window.scrollTo(0, 0);
        // }, 350)
        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual';
        }
        this.resetData()
        this.resetData = debounce(this.resetData, 300)
        // 换皮防抖
        this.changeLight = debounce(this.changeLight, 300)
        // window.addEventListener("resize", () => {
        //     window.location.reload()
        // })
        window.addEventListener("scroll", this.handleScroll);

    },
    beforeUpdate () {
        this.$nextTick(() => {
            this.resetData() // 打包后加载异常处理
        })
    },
    beforeUnmount () {
        this.tsClass = false;
        window.scrollTo(0, 0);
    },
    methods: {
        resetPage () {
        },
        changeLight () {
            this.light = !this.light
            if (this.light) {
                document.body.style.background = '#000'
            } else {
                document.body.style.background = '#fff'
            }
        },
        // 浏览器变化重新加载数据
        resetData () {
            if (this.topHeight == this.$refs.pageTop.offsetHeight + 'px') {
                return
            }
            this.isClick = false
            // let windowWidth = document.documentElement.clientWidth || document.body.clientWidth;
            let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            this.topHeight = this.$refs.pageTop.offsetHeight + 'px'
            // if (windowWidth / windowHeight > 0.75) {
            if (this.$refs.tscd.$el.offsetTop > windowHeight) {
                // this.tsStyle.height = this.$refs.pageTop.offsetHeight + 'px'
                this.tsStyle.top = (-this.$refs.tscd.$el.offsetTop + windowHeight) + 'px'
                this.headLiShow = false
            } else {
                this.headLiShow = true
            }
            // this.tsStyle.top = this.$refs.topBox.offsetHeight + 'px'
            // this.tsStyle.height = this.$refs.topBox.offsetHeight + 'px'
            let selfTopArr = [];
            let selfHeightArr = [];
            for (let i = 0; i < arrList.length; i++) {
                selfTopArr.push(this.$refs[arrList[i]].$el.offsetTop - 160)
                selfHeightArr.push(this.$refs[arrList[i]].$el.offsetHeight)
            }
            this.selfTopArr = selfTopArr
            this.selfHeightArr = selfHeightArr
            this.$nextTick(() => {
                this.isClick = true
                this.createShow = false
            })
        },
        // 实现滚动条平滑滚动的方法
        scrollToCmtList (val) {
            // 1.1 返回文档在垂直方向已滚动的像素值
            const now = window.scrollY
            // 1.2 目标位置（文章信息区域的高度）
            let dist = val
            // 1.3 可滚动高度 = 整个文档的高度 - 浏览器窗口的视口（viewport）高度
            const avalibleHeight =
                document.documentElement.scrollHeight - window.innerHeight

            // 2.1 如果【目标高度】 大于 【可滚动的高度】
            if (dist > avalibleHeight) {
                // 2.2 就把目标高度，设置为可滚动的高度
                dist = avalibleHeight
            }

            // 3. 动态计算出步长值
            const step = (dist - now) / 10
            setTimeout(() => {
                // 4.2 如果当前的滚动的距离不大于 1px，则直接滚动到目标位置，并退出递归
                if (Math.abs(step) <= 10) {
                    return window.scrollTo(0, dist)
                }
                // 4.1 每隔 10ms 执行一次滚动，并递归地进行下一次的滚动
                window.scrollTo(0, now + step)
                this.scrollToCmtList(val)
            }, 10)
        },
        // 跳转指定模块
        toPath (tag) {
            // 跳转前隐藏tscd页
            this.showTscd = true
            setTimeout(() => {
                this.showTscd = false
            }, 500);
            // 如果点击的是logo回到头部
            if (tag == 'logo') {
                this.scrollToCmtList(0)
                return
            }

            if (!this.isClick) {
                return
            }
            // bug解决当再头部直接跳转产品方案页因为有折页产生定位计算异常
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop == 0 && tag == 'cpfa') {
                this.color = true;
                this.serarea.height = this.topHeight
                this.tsClass = true;
                this.$nextTick(() => {
                    this.scrollToCmtList(this.$refs[tag].$el.offsetTop - 150)
                    return
                })
            }

            // 常规跳页
            this.$nextTick(() => {
                this.scrollToCmtList(this.$refs[tag].$el.offsetTop - 150)
            })
            // window.scrollTo(0, this.$refs[tag].$el.offsetTop - 100)
        },
        handleScroll () {
            if (!this.isClick && this.createShow) {
                window.scrollTo(0, 0)
                return
            }
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            let scrollHeight =
                document.documentElement.scrollHeight ||
                document.body.scrollHeight;
            let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

            let selfTopArr = this.selfTopArr
            let selfHeightArr = this.selfHeightArr
            // 高度集合，循环赋值
            for (let n = selfTopArr.length - 1; n >= 0; n--) {
                if (scrollTop < (selfTopArr[n] + selfHeightArr[n]) && scrollTop >= (selfTopArr[n - 1] + selfHeightArr[n - 1])) {
                    this.$refs.headTop.changeColor(arrList[n])
                    break;
                } else if (scrollTop < 500) {
                    this.$refs.headTop.changeColor(arrList[0])
                    break;
                }
            }

            //设置背景颜色的透明读
            if (scrollTop) {
                // this.style.backgroundColor = '`rgba(61, 61, 61,${scrollTop /
                //     (scrollTop + 80)})'`;
                this.color = true;
                // let setHeight = this.$refs.qyjj.$el.offsetTop - 100
                if (scrollTop >= 150) {
                    this.lcbBox = true
                }
                if (scrollTop >= this.$refs.tscd.$el.offsetTop - windowHeight) {
                    // if (scrollTop >= setHeight) {
                    // let num = (windowHeight - (scrollTop - setHeight)) / windowHeight
                    // if (num <= 0 || num == 'NaN') {
                    //     num = 0
                    // }
                    // this.tsStyle.opacity = num;
                    // this.tsStyle.top = this.$refs.cpfa.$el.offsetTop - scrollTop + setHeight + 'px'
                    // console.log(this.$refs.pageTop.offsetHeight)
                    this.serarea.height = this.topHeight
                    this.tsClass = true;
                } else {
                    this.serarea.height = 0
                    this.tsClass = false;
                }
                if (scrollHeight == scrollTop + windowHeight) {
                    this.$refs.headTop.changeColor("lxwm")
                }
            } else if (scrollTop == 0) {
                // this.style.backgroundColor = "transparent";
                this.color = false;
            }
        },
        showPop (name) {
            this.$refs.ClickPop.showIng(name)
        }
    }
}
</script>

<style scoped lang="less">
.page {
    // width: 1440px;
    // margin: 0 auto;
    min-width: 1750px;
    position: relative;
    .top-box {
        width: 1750px;
        margin: 0 auto;
    }
}
/deep/.color {
    box-shadow: 0px -6px 20px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
}
.tsClass {
    // z-index: -1;
    box-sizing: border-box;
    position: fixed;
    width: 1750px;
}
/deep/ .page-bottom > ul {
    background-color: #fff;
    z-index: 2;
}
/deep/ .page-bottom > div {
    background-color: #fff;
    z-index: 2;
}
.logoImg {
    width: 130px;
    height: 42px;
    cursor: pointer;
}
.btn-img-head {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
}
.img-li-head {
    position: relative;
    cursor: pointer;
    &:hover {
        &::after {
            content: '敬请期待';
            display: inline-block;
            position: absolute;
            line-height: 32px;
            font-size: 12px !important;
            width: 120px;
            bottom: -40px;
            left: -40px;
            border-radius: 5px;
            background-color: #fff;
            color: #000 !important;
            text-align: center;
        }
    }
}

.lcb-ul {
    li {
        font-size: 22px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        line-height: 44px;
    }
    .text {
        opacity: 0;
        // animation: typing 2s;
        // white-space: nowrap;
        // overflow: hidden;
    }
}

.marginR20 {
    margin-right: 20px;
}

.right-banner {
    position: relative;
    .family-banner {
        width: 800px;
        height: 550px;
        // margin-right: 110px;
    }
    .banner-img {
        position: absolute;
        bottom: -150px;
        // right: 110px;
        right: 0;
        width: 125px;
        height: 125px;
        cursor: pointer;
    }
}
div.lxwm-btn {
    width: 390px;
    height: 64px;
    border-radius: 32px;
    border: 1px solid rgba(255, 255, 255, 0.9);

    font-size: 22px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #ffffff;
    line-height: 64px;
    text-align: center;
    cursor: pointer;
}
.showTscd {
    opacity: 0;
}
.gif-loading {
    position: absolute;
    right: 300px;
    bottom: -30px;
    width: 400px;
    height: 300px;
}
</style>
<style lang="less">
.lcbBox {
    .text {
        animation: typing 2s;
        white-space: nowrap;
        overflow: hidden;
    }
    .text1 {
        animation: typing ease-in 2s;
        animation-fill-mode: forwards;
    }
    .text2 {
        animation: typing 2s ease-in 2s;
        animation-fill-mode: forwards;
    }
    .text3 {
        animation: typing 2s ease-in 3s;
        animation-fill-mode: forwards;
    }
    .text4 {
        animation: typing 2s ease-in 4s;
        animation-fill-mode: forwards;
    }
    .text5 {
        animation: typing 2s ease-in 5s;
        animation-fill-mode: forwards;
    }
    .text6 {
        animation: typing 2s ease-in 6s;
        animation-fill-mode: forwards;
    }
    .text7 {
        animation: typing 2s ease-in 7.2s;
        animation-fill-mode: forwards;
    }
    @keyframes typing {
        0% {
            width: 0;
            opacity: 1;
        }
        100% {
            width: 100%;
            opacity: 1;
        }
    }
}
.btn-lcb {
    width: 290px;
    height: 64px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 32px;
    font-size: 22px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    line-height: 64px;
    text-align: center;
    margin-top: 60px;
    cursor: pointer;
}
.unlight {
    .box-bagc {
    }
    .page-bottom > ul {
        background-color: #000 !important;
        color: #fff !important;
    }
    .page-bottom > div {
        background: #000 !important;
        color: #fff !important;
    }
    .page-top > ul {
        background: #000 !important;
        color: #fff !important;
    }
    .page-top > div {
        background: #000 !important;
        color: #fff !important;
    }
    & {
        background: #000 !important;
        color: #fff !important;
    }
    .headerColor {
        background: #000 !important;
        li {
            color: #fff !important;
        }
    }
    .h4-title-pc {
        color: #fff !important;
    }
    .btn-lcb {
        background: rgba(255, 255, 255, 0.15) !important;
    }
    .tscd > div {
        // background: #000 !important;
    }
    .cpfa li.cp-box {
        background: #000 !important;
    }
    .footer {
        color: #000 !important;
    }
    .jjfa .center-box {
        background: #000 !important;
        span.left,
        span.right {
            background: #fff !important;
        }
        .title-demo {
            color: #fff !important;
        }
    }
}
</style>
