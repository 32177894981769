<template>
    <ul class="cpfa">
        <div class="center-box">
            <div class="background-info"></div>
            <li class="cp-box ">
                <div class="videoBox">
                    <img class="img-box-cp" src="../../assets/cp1.gif" alt="" />
                </div>
                <div class="info-cpfa">
                    <h4 class="h4-title">
                        增值3D++<br />
                        资产管理平台
                    </h4>
                    <p>
                        3D++资产管理平台台有效解决了传统三维信息割裂、无法统一管理等痛点，同时轻量化打通了三维信息交互多项壁垒，让用户实现一站式可视化互动管控，处理过程全自动化，颠覆了传统三维人工处理方式，为可视化生态提供了全新的可能。
                    </p>
                </div>
            </li>
        </div>
    </ul>
</template>

<script>
// import showView from './showView.vue'
export default {
    name: 'cpfaCom',
    components: {
        // showView
    },
}
</script>

<style scoped lang="less">
.cpfa {
    // box-shadow: ;
    // width: 1750px;
    // margin: 0 auto;
    // padding: 0 80px;
    width: 100vw;
    position: relative;
    overflow: hidden;
    // div.shadow {
    //     // content: '';
    //     position: absolute;
    //     display: block;
    //     top: 0px;
    //     z-index: 1;
    //     background-color: #fff;
    //     width: 100%;
    //     height: 60px;
    //     width: calc(100% - 240px);
    //     left: 120px;
    //     // border-radius: 20px;
    //     box-shadow: -3px -8px 30px 0px rgb(0 0 0 / 80%);
    // }
    .h4-title {
        margin-top: 4vh;
        padding-top: 30px;
        margin-bottom: 4vh;
        margin-left: 40px;
    }
    .top-box {
        padding-top: 180ox;
        position: relative;
        z-index: 2;
    }
    .center-box {
        position: relative;
    }
    .background-info {
        position: absolute;
        background-image: url(../../assets/diwen.png);
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 1;
        // height: 100%;
    }
    li.cp-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        // justify-content: space-between;
        // background-color: #fff;
        // padding: 200px 0px;
        position: relative;
        width: 100%;
        margin: 0 auto;
        height: 100vh;
        margin: auto 0;
        .videoBox {
            margin-top: 76px;
            height: 36vh;
            width: 100%;
            overflow: hidden;
            position: relative;
            .img-box-cp {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                // width: 760px;
                z-index: 1;
                object-fit: cover;
                // background-color: rgba(0, 0, 0, 0.3);
                // margin-right: 80px;
            }
        }

        p {
            font-size: 36px;
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            color: #858a8c;
            line-height: 64px;
            box-sizing: border-box;
            padding: 0 40px;
        }
        h4 {
            line-height: 54px;
            height: unset;
        }
        .info-cpfa {
            width: 100%;
            z-index: 1;
        }
        .flex-just {
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
        }
        .info-span {
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            color: #858a8c;
            margin-left: 40px;
            line-height: 32px;
            // margin-bottom: 35px;
            margin-top: 60px;
            li {
                font-size: 16px;
            }
        }
    }
}
</style>
