<template>
    <div class="ClickPop" v-show="isShow">
        <div class="main-box">
            <img src="../../assets/close.png" class="cls-btn" @click="unShow" alt="">
            <h5>{{title}}</h5>
            <ul>
                <li>电话：13261232580</li>
                <li>邮箱：xuanjing@magicalscene.com</li>
                <li>地址：北京市海淀区苏州街3号大恒科技大厦北座15层</li>
            </ul>
            <!-- <img src="../../assets/map.png" class="bottom-box" alt=""> -->
            <div class="bottom-box">
                <IndexMap></IndexMap>
            </div>
        </div>
    </div>
</template>

<script>
import IndexMap from '../map/index.vue'
export default {
    name: 'ClickPopCom',
    components: {
        IndexMap
    },
    data () {
        return {
            isShow: false,
            title: ''
        }
    },
    methods: {
        showIng (name) {
            this.title = name
            this.isShow = true
        },
        unShow () {
            this.isShow = false
        }
    }
}
</script>

<style scoped lang="less">
.ClickPop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99;
    .main-box {
        position: fixed;
        left: 50%;
        top: 150px;
        width: 620px;
        transform: translateX(-50%);
        background-color: #fff;
        padding: 32px;
        z-index: 100;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h5 {
            font-size: 22px;
            margin-bottom: 24px;
            text-align: center;
            color: #000000;
            line-height: 32px;
            font-weight: 600;
        }
        ul {
            margin-bottom: 24px;
            li {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #858a8c;
                line-height: 32px;
                text-align: center;
            }
        }
        .cls-btn {
            position: absolute;
            right: 20px;
            top: 20px;
            width: 21px;
            height: 21px;
            cursor: pointer;
        }
        .bottom-box {
            width: 558px;
            height: 160px;
        }
    }
}
</style>
