<template>
    <div class="qyjj">
        <p>
            <img src="../../assets/qyzp.jpg" alt="" />
        </p>
        <h4 class="h4-title margin-title">大恒炫璟</h4>
        <div class="qyjj-info">
            依托大恒集团在三维渲染引擎、人工智能内容生成技术、专业视音频处理能力等方面的技术积累，自主研发智能三维可交互场景编辑工具，实现跨平台多终端的AIGC三维动态场景展示与互动，为客户提供三维交互场景定制、开发和销售等服务。
        </div>
    </div>
</template>

<script>
export default {
    name: 'qyjjCom',
}
</script>

<style scoped lang="less">
.qyjj {
    color: #000000;
    height: 100vh;
    width: 100vw;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    > p {
        height: 30vh;
        margin-top: 76px;
        width: 100%;
        overflow: hidden;
        position: relative;
        img {
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .margin-title {
        margin-top: 4vh;
        padding-top: 30px;
        margin-bottom: 4vh;
        margin-left: 40px;
    }

    .qyjj-info {
        width: 100%;
        font-size: 36px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        line-height: 64px;
        padding: 0 40px;
        box-sizing: border-box;
    }
}
</style>
