<template>
    <bodyPage class="PC" v-if="isPC" />
    <phonePage class="phone" v-else />
</template>

<script>
import bodyPage from './components/index.vue'
import phonePage from './components/phone.vue'
const debounce = function (func, wait) {
    let timeout
    return function () {
        let context = this // 新增
        // 保存参数
        let args = arguments // 新增

        clearTimeout(timeout)
        timeout = setTimeout(function () {
            func.apply(context, args) // 修改
        }, wait);
    }
}
export default {
    name: 'App',
    components: {
        bodyPage,
        phonePage
    },
    data () {
        return {
            isPC: false,
            // showPage: false
        }
    },
    created () {
        // this.showPage = false
        let windowWidth = document.documentElement.clientWidth || document.body.clientWidth;
        if (windowWidth <= 800) {
            this.isPC = false
        } else {
            this.isPC = true
        }
    },
    mounted () {
        let reload = debounce(this.reload, 300)
        window.addEventListener("resize", () => {
            reload()
        })
        // this.showPage = true
    },
    methods: {
        reload () {
            window.location.reload()
        }
    }
}
</script>

<style lang="less">
@import url('../src/css/main.css');

// @media screen and (min-width: 320px) {

// }

// @media screen and (min-width: 360px) {
//     html {
//         font-size: 16px;
//     }
// }

// @media screen and (min-width: 400px) {
//     html {
//         font-size: 18px;
//     }
// }

// @media screen and (min-width: 440px) {
//     html {
//         font-size: 20px;
//     }
// }

// @media screen and (min-width: 480px) {
//     html {
//         font-size: 22px;
//     }
// }

// @media only screen and (max-width: 801px) {
//     .PC {
//         display: none !important;
//     }
//     .phone {
//         display: block !important;
//     }
// }
// @media only screen and (min-width: 800px) {
//     .PC {
//         display: block !important;
//     }
//     .phone {
//         display: none !important;
//     }
// }
</style>
