import { createApp } from 'vue'
import App from './App.vue'

import 'lib-flexible'
// import '@/assets/css/reset.css' 

window._AMapSecurityConfig = {
	securityJsCode: '523d62a249b4abca00c3a0514674bee6' //*  安全密钥
}

createApp(App).mount('#app')
