<template>
    <div class="lcb">
        <h4 class="h4-title-pc">公司里程碑</h4>
        <slot></slot>
        <!-- <div class="btn-lcb">Join us</div> -->
    </div>
</template>

<script>
export default {
    name: 'lcbCom',
}
</script>

<style scoped lang="less">
.lcb {
    margin-bottom: 270px;
    padding: 0 120px;
    padding-top: 150px;
    color: #000000;
    // ul {
    //     li {
    //         font-size: 22px;
    //         font-family: PingFang-SC-Heavy, PingFang-SC;
    //         font-weight: 800;
    //         line-height: 44px;
    //     }
    // }
    // .btn-lcb {
    //     width: 290px;
    //     height: 64px;
    //     background: rgba(0, 0, 0, 0.05);
    //     border-radius: 32px;
    //     font-size: 22px;
    //     font-family: PingFang-SC-Heavy, PingFang-SC;
    //     font-weight: 800;
    //     line-height: 64px;
    //     text-align: center;
    //     margin-top: 60px;
    //     cursor: pointer;
    // }
    // .text {
    //     opacity: 0;
    //     animation: typing 2s;
    //     white-space: nowrap;
    //     overflow: hidden;
    // }
    // .text1 {
    //     animation: typing 1s;
    //     animation-fill-mode: forwards;
    // }
    // .text2 {
    //     animation: typing 1s 1s;
    //     animation-fill-mode: forwards;
    // }
    // .text3 {
    //     animation: typing 1s 2s;
    //     animation-fill-mode: forwards;
    // }
    // .text4 {
    //     animation: typing 1s 3s;
    //     animation-fill-mode: forwards;
    // }
    // .text5 {
    //     animation: typing 2s 4s;
    //     animation-fill-mode: forwards;
    // }
    // .text6 {
    //     animation: typing 1s 5s;
    //     animation-fill-mode: forwards;
    // }

    // @keyframes typing {
    //     0% {
    //         width: 0;
    //         opacity: 1;
    //     }
    //     100% {
    //         width: 100%;
    //         opacity: 1;
    //     }
    // }
}
</style>
