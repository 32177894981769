<template>
    <div style="position:relative;">
        <ul class="footer">
            <li><a href="https://beian.miit.gov.cn/" style="color: rgba(255,255,255,.8);" target="_blank">京ICP备2022009335号-1</a></li>
        </ul>
        <div class="shadow"></div>
    </div>
</template>

<script>

export default {
    name: 'pageFooter',

}
</script>

<style scoped lang="less">
.footer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 200px;
    // border: 1px solid #000;
    // border-top: unset;
    // opacity: 0.8;
    margin-bottom: 50px;
    background-color: #0d0d0d;
    text-align: center;
    z-index: 2;
    li {
        font-size: 12px;
        line-height: 36px;
        span {
            color: #06c;
        }
    }
}
div.shadow {
    // content: '';
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 1;
    background-color: #fff;
    width: 100%;
    height: 100%;
    // border-radius: 20px;
}
</style>
