<template>
    <pageHeader class="headerColor color" ref="headTop">
        <img src="../assets/LOGO.png" class="logoImg" alt="LOGO" @click="toPath(0)" />
    </pageHeader>
    <div id="swipercom">
        <div class="swiper-container" id="swiperIndex" ref="swiperDom">
            <div class="swiper-wrapper">
                <div class="swiper-slide" :key="1">
                    <banner ref="logo">
                        <img src="../assets/downRun.gif" class="banner-img" @click="toPath(1)" alt="" />
                    </banner>
                </div>
                <div class="swiper-slide" :key="2">
                    <lcb ref="lcb">
                        <ul :class="{lcbBox:lcbBox}" class="lcb-ul">
                            <!-- <li class="text text1">2022年12月，场景管理平台V1.0上线;</li>
                            <li class="text text2">2022年9月，3D++资产管理平台V3.0上线;</li>
                            <li class="text text3">2022年7月，3D++资产管理平台V2.0上线;</li>
                            <li class="text text4">2022年5月，3D++资产管理平台V1.0正式上线;</li>
                            <li class="text text5">2022年1月，大恒炫璟数字人及各行业解决方案上线;</li>
                            <li class="text text6">2021年6月， 北京大恒炫璟科技有限责任公司正式成立;</li> -->
                            <li>{{text1}}</li>
                            <li>{{text2}}</li>
                            <li>{{text3}}</li>
                            <li>{{text4}}</li>
                            <li>{{text5}}</li>
                            <li>{{text6}}</li>
                            <li>{{text7}}</li>
                        </ul>
                    </lcb>
                </div>
                <div class="swiper-slide" :key="3">
                    <qyjj />
                </div>
                <div class="swiper-slide" :key="4">
                    <div class="box-bagc">
                        <cp1></cp1>
                    </div>
                </div>
                <div class="swiper-slide" :key="5">
                    <div class="box-bagc">
                        <cp2></cp2>
                    </div>
                </div>
                <div class="swiper-slide" :key="6">
                    <div class="box-bagc">
                        <cp3></cp3>
                    </div>
                </div>
                <div class="swiper-slide" :key="7">
                    <div class="box-bagc">
                        <jjfa></jjfa>
                    </div>
                </div>
                <div class="swiper-slide" :key="8">
                    <ClickPop ref="ClickPop"></ClickPop>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import banner from './phoneCom/banner.vue'
import lcb from './phoneCom/lcb.vue'
import "swiper/css/swiper.css"; //引入swiper样式
import Swiper from "swiper"; //引入swiper
import qyjj from './phoneCom/qyjj.vue'
import cp1 from './phoneCom/cp1.vue'
import cp2 from './phoneCom/cp2.vue'
import cp3 from './phoneCom/cp3.vue'
import jjfa from './phoneCom/jjfa.vue'
import ClickPop from './phoneCom/ClickPop.vue'

import pageHeader from '../components/phoneHead.vue'
const debounce = function (func, wait) {
    let timeout
    return function () {
        let context = this // 新增
        // 保存参数
        let args = arguments // 新增

        clearTimeout(timeout)
        timeout = setTimeout(function () {
            func.apply(context, args) // 修改
        }, wait);
    }
}

export default {
    name: 'indexPage',
    components: {
        banner,
        lcb,
        qyjj,
        pageHeader,
        cp1,
        cp2,
        cp3,
        ClickPop,
        jjfa
    },
    computed: {
        page () {
            return this.$refs["swiperDom"].swiper
        }
    },
    data () {
        return {
            color: true,
            tsStyle: {
                top: 0,
                opacity: 1
            },
            serarea: {
                height: 0,
                width: '1px'
            },
            tsClass: false,
            pageHeight: {
                height: 'auto'
            },
            topHeight: 0,    // 折页处理需要用到
            light: false,   // 换肤用常规css处理的
            lcbBox: false,   // 里程碑动画展示触发条件
            isClick: false,     // 判断数据是否加载好在跳转滚轮
            showTscd: false,  // 隐藏tscd页
            selfTopArr: [],
            selfHeightArr: [],
            createShow: true,
            mySwiper: null,
            TextList: [
                // "2022年12月，场景管理平台V1.0上线;",
                // "2022年9月，3D++资产管理平台V3.0上线;",
                // "2022年7月，3D++资产管理平台V2.0上线;",
                // "2022年5月，3D++资产管理平台V1.0正式上线;",
                // "2022年1月，大恒炫璟数字人及各行业解决方案上线;",
                // "2021年6月， 北京大恒炫璟科技有限责任公司正式成立，是大恒科技（600288）面向元宇宙业务投资成立的控股子公司。"
                "2022年2月， 北京大恒炫璟科技有限责任公司正式成立，是大恒科技（600288）面向元宇宙业务投资成立的控股子公司",
                "2022年3月，大恒炫璟全行业数字解决方案上线",
                "2022年5月，3D++资产管理平台V1.0正式上线",
                "2022年7月，3D++资产管理平台V2.0上线",
                "2022年9月，3D++资产管理平台V3.0上线",
                "2022年10月，大恒炫璟面向教育行业元宇宙实训室解决方案上线",
                "2022年12月，场景管理平台V1.0上线",
            ],
            timerList: {
                timer1: "",
                timer2: "",
                timer3: "",
                timer4: "",
                timer5: "",
                timer6: "",
            },
            // showTextList: [
            //     "", "", "", "", "", ""
            // ]
            text1: "",
            text2: "",
            text3: "",
            text4: "",
            text5: "",
            text6: "",
            text7: "",
        };
    },
    mounted () {
        this.tsClass = false;
        // 计算高度方便后期运算
        // setTimeout(() => {
        //     this.tsClass = false;
        //     window.scrollTo(0, 0);
        // }, 350)
        this.mySwiper = new Swiper("#swiperIndex", {
            //配置分页器内容
            direction: "vertical",
            mousewheel: true,
            loop: false, // 循环模式选项
            // pagination: {
            //     el: ".swiper-pagination", //换页器与哪个标签关联
            //     clickable: true, //分页器是否可以点击
            // },
            // lcbBox
            // touchAngle : 10,
            on: {
                transitionEnd: async () => {
                    if (!this.lcbBox && this.page.activeIndex == 1) {
                        this.lcbBox = true
                        // this.TextList.forEach((item, num) => {
                        //     this.appear(
                        //         item, num + 1
                        //     );
                        // })
                        this.resetPage()
                    }
                },
            },
        });
        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual';
        }
        this.resetData()
        this.resetData = debounce(this.resetData, 300)
        // 换皮防抖
        this.changeLight = debounce(this.changeLight, 300)
        window.addEventListener("resize", () => {
            window.location.reload()
        })
        window.addEventListener("scroll", this.handleScroll);

    },
    beforeUpdate () {
        this.$nextTick(() => {
            this.resetData() // 打包后加载异常处理
        })
    },
    beforedestroy () {
        this.tsClass = false;
        window.scrollTo(0, 0);
    },
    methods: {
        resetPage () {
            this.appear(this.TextList[0], 1)
            setTimeout(() => { this.appear(this.TextList[1], 2) }, 2300)
            setTimeout(() => { this.appear(this.TextList[2], 3) }, 3500)
            setTimeout(() => { this.appear(this.TextList[3], 4) }, 4900)
            setTimeout(() => { this.appear(this.TextList[4], 5) }, 6200)
            setTimeout(() => { this.appear(this.TextList[5], 6) }, 7500)
            setTimeout(() => { this.appear(this.TextList[6], 7) }, 9000)
        },
        changeLight () {
            this.light = !this.light
            if (this.light) {
                document.body.style.background = '#000'
            } else {
                document.body.style.background = '#fff'
            }
        },
        appear (content, num) {
            const _this = this;

            this['text' + num] = "";
            clearTimeout(this.timerList['timer' + num]);

            var speed = 30; //设置定时的速度 越来越快
            var count = 1;

            function changeContent () {
                _this['text' + num] = content.substring(0, count); //截取字符串
                count++;

                if (count != content.length + 1) {
                    // speed -= 1;
                    // if (speed < 5) speed = 5;

                    _this.timerList['timer' + num] = setTimeout(changeContent, speed);
                }
            }
            changeContent();
        },
        // 浏览器变化重新加载数据
        resetData () {

        },
        // 实现滚动条平滑滚动的方法
        scrollToCmtList (val) {
            // 1.1 返回文档在垂直方向已滚动的像素值
            const now = window.scrollY
            // 1.2 目标位置（文章信息区域的高度）
            let dist = val
            // 1.3 可滚动高度 = 整个文档的高度 - 浏览器窗口的视口（viewport）高度
            const avalibleHeight =
                document.documentElement.scrollHeight - window.innerHeight

            // 2.1 如果【目标高度】 大于 【可滚动的高度】
            if (dist > avalibleHeight) {
                // 2.2 就把目标高度，设置为可滚动的高度
                dist = avalibleHeight
            }

            // 3. 动态计算出步长值
            const step = (dist - now) / 10
            setTimeout(() => {
                // 4.2 如果当前的滚动的距离不大于 1px，则直接滚动到目标位置，并退出递归
                if (Math.abs(step) <= 1) {
                    return window.scrollTo(0, dist)
                }
                // 4.1 每隔 10ms 执行一次滚动，并递归地进行下一次的滚动
                window.scrollTo(0, now + step)
                this.scrollToCmtList(val)
            }, 10)
        },
        // 跳转指定模块
        toPath (tag) {
            this.mySwiper.slideTo(tag, 1000, false);
            this.$nextTick(() => {
                setTimeout(() => {
                    if (!this.lcbBox && this.page.activeIndex == 1) {
                        this.lcbBox = true
                        // this.TextList.forEach((item, num) => {
                        //     this.appear(
                        //         item, num + 1
                        //     );
                        // })
                        this.resetPage()
                    }
                }, 1000)
            })
        },
        handleScroll () {
        },
        showPop (name) {
            this.$refs.ClickPop.showIng(name)
        }
    }
}
</script>

<style scoped lang="less">
.swiper-container {
    width: 100vw;
    height: 100vh;
}
.page {
    // width: 1440px;
    // margin: 0 auto;
    position: relative;
    overflow: hidden;
    width: 100vw !important;
    overflow-x: hidden;
    .top-box {
        width: 1750px;
        margin: 0 auto;
    }
}
/deep/ .color {
    box-shadow: 0px -6px 20px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
}

.banner-img {
    width: 300px;
    height: 300px;
    align-self: center;
}

.tsClass {
    // z-index: -1;
    box-sizing: border-box;
    position: fixed;
    width: 1750px;
}
/deep/ .page-bottom > ul {
    background-color: #fff;
    z-index: 2;
}
/deep/ .page-bottom > div {
    background-color: #fff;
    z-index: 2;
}
.logoImg {
    width: 130px;
    height: 42px;
    cursor: pointer;
}
.btn-img-head {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
}
.img-li-head {
    position: relative;
    cursor: pointer;
    &:hover {
        &::after {
            content: '敬请期待';
            display: inline-block;
            position: absolute;
            line-height: 32px;
            font-size: 12px !important;
            width: 120px;
            bottom: -40px;
            left: -40px;
            border-radius: 5px;
            background-color: #fff;
            color: #000 !important;
            text-align: center;
        }
    }
}

.lcb-ul {
    li {
        font-size: 36px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        // line-height: 55px;
        padding: 45px 0;
        line-height: 56px;
    }
    .text {
        opacity: 0;
        // animation: typing 2s;
        // white-space: nowrap;
        // overflow: hidden;
    }
}

.marginR20 {
    margin-right: 20px;
}

div.lxwm-btn {
    width: 390px;
    height: 64px;
    border-radius: 32px;
    border: 1px solid rgba(255, 255, 255, 0.9);

    font-size: 22px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #ffffff;
    line-height: 64px;
    text-align: center;
    cursor: pointer;
}
.showTscd {
    opacity: 0;
}
.gif-loading {
    position: absolute;
    right: 300px;
    bottom: -80px;
    width: 400px;
    height: 300px;
}
</style>
<style lang="less">
.lcbBox {
    .text {
        // animation: typing 2s;
        // white-space: nowrap;
        // overflow: hidden;
    }
    // .text1 {
    //     animation: typing ease-in 2s;
    //     animation-fill-mode: forwards;
    // }
    // .text2 {
    //     animation: typing 2s ease-in 1s;
    //     animation-fill-mode: forwards;
    // }
    // .text3 {
    //     animation: typing 2s ease-in 2s;
    //     animation-fill-mode: forwards;
    // }
    // .text4 {
    //     animation: typing 2s ease-in 3s;
    //     animation-fill-mode: forwards;
    // }
    // .text5 {
    //     animation: typing 2s ease-in 4s;
    //     animation-fill-mode: forwards;
    // }
    // .text6 {
    //     animation: typing 2s ease-in 5s;
    //     animation-fill-mode: forwards;
    // }
    // .text7 {
    //     animation: typing 2s ease-in 6s;
    //     animation-fill-mode: forwards;
    // }
    // @keyframes typing {
    //     0% {
    //         width: 0;
    //         opacity: 1;
    //     }
    //     100% {
    //         width: 100%;
    //         opacity: 1;
    //     }
    // }
}
.btn-lcb {
    width: 290px;
    height: 64px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 32px;
    font-size: 22px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    line-height: 64px;
    text-align: center;
    margin-top: 60px;
    cursor: pointer;
}
.linHeight {
    line-height: 50px;
}
</style>
